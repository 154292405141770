import { Grid } from "antd"

export const smallScreens = ["xs", "sm", "md"]

export default () => {
  const breakpoint = Grid.useBreakpoint()
  const activeBreakpoints = Object.entries(breakpoint)
    .filter(screen => !!screen[1])
    .map(screen => screen[0])
  const currentBreakpoint = activeBreakpoints.slice(-1)[0]
  const returnValues = {
    breakpoint: currentBreakpoint,
    active: activeBreakpoints,
    isSmallScreen: smallScreens.includes(currentBreakpoint)
  }
  return returnValues
}
